import { CachePolicies, useFetch } from 'use-http';
import { StringParam, useQueryParam } from 'use-query-params';
import { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { routes } from '@/webapi/routes';
import { AccountContext } from '@/features/account-context';

interface CreateResponse {
  storeAlias: string;
  firstName: string;
  lastName: string;
  email: string;
  uuid: string;
}

interface VerifyLoginResponse {
  accessToken: string;
}

export function useEmailLanding() {
  const ctx = useContext(AccountContext);

  const { post, error, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: true,
    },
  }));

  const [resp, setResp] = useState(``);
  const [magicToken] = useQueryParam(`q`, StringParam);
  const [type] = useQueryParam(`t`, StringParam);
  const [alias] = useQueryParam(`alias`, StringParam);

  useEffect(() => {
    if (magicToken && type) {
      (async () => {
        const resp = (await post(
          type === `signup` ? routes.initShop() : routes.verifyLogin(),
          {
            magicToken,
            alias: alias || ``,
          },
        )) as CreateResponse | VerifyLoginResponse;

        if (isOk(resp)) {
          await ctx.fetchUserContext();
          await navigate(`/dashboard`);
        } else {
          setResp(JSON.stringify(resp));
        }
      })();
    }
  }, [magicToken, type]);

  return { error, loading, type, resp };
}

function isOk(resp: CreateResponse | VerifyLoginResponse) {
  return (
    (resp as CreateResponse)?.uuid || (resp as VerifyLoginResponse)?.accessToken
  );
}
